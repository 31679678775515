import './CapacitiesList.css';

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    capacities: Capacity[];
};

const icons = {
    1: {
        icon: 'male',
        name: 'ambulatory',
    },
    2: {
        icon: 'wheelchair',
        name: 'wheelchair',
    },
    3: {
        icon: 'suitcase',
        name: 'luggage',
    },
    4: {
        icon: 'walker',
        name: 'assistive-device',
    },
    5: {
        icon: 'stretcher',
        name: 'stretcher',
    },
    6: {
        icon: 'chair',
        name: 'front-seat',
    },
};

const CapacitiesList = ({ capacities }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className="capacities-list">
            {capacities.map((i) => {
                const cap = icons[i.unitId]
                    ? icons[i.unitId]
                    : { icon: 'question', name: i.unitId };
                return (
                    <span
                        className="capacity-item"
                        key={i.unitId}
                        title={t(`capacity.${cap.name}`)}
                    >
                        <FontAwesomeIcon icon={cap.icon} /> {i.count}
                    </span>
                );
            })}
        </div>
    );
};

export default CapacitiesList;
