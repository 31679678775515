export const genFormattedAddress = (location: Location) => {
    const keys: string[] = [
        'alias',
        'street',
        'streetNumber',
        'door',
        'city',
        'zipcode',
        'country',
        'province',
    ];

    return keys
        .reduce((a, i) => (location[i] ? [...a, location[i]] : a), [] as string[])
        .join(', ');
};

export const genUrl = (
    path: string,
    search: string,
    params: { [key: string]: Undef<string> } = {}
): string => {
    const q: URLSearchParams = new URLSearchParams(search);
    Object.entries(params).forEach(([key, value]: [string, Undef<string>]) =>
        value ? q.set(key, value) : q.delete(key)
    );
    search = q.toString();

    return `${path}${search ? `?${search}` : ''}`;
};

export const getQP = (search: string, key): Undef<string> => {
    const q: URLSearchParams = new URLSearchParams(search);

    return q.get(key) || undefined;
};
