import * as polyUtil from 'polyline-encoded';
import { action, computed, observable } from 'mobx';
import StopModel from './StopModel';
import RouteModel from './RouteModel';

export default class TripModel implements ITrip {
    static get STATE_NO_SHOW() {
        return 'noShow';
    }
    static get STATE_NOT_STARTED() {
        return 'notStarted';
    }
    static get STATE_ARRIVING_PICKUP() {
        return 'arrivingPickup';
    }
    static get STATE_ON_BOARD() {
        return 'onBoard';
    }
    static get STATE_FINISHED() {
        return 'finished';
    }

    id: number;
    requestedTime: Date;
    batchId: any;
    routeId: number;
    externalId: any;
    contract: any;
    anchor: StopType;
    sharing: string;
    distanceLimit: TripLimit;
    timeLimit: TripLimit;
    passenger: Passenger;
    start: Location;
    end: Location;
    ride: Ride;
    capacity: Capacity[];
    attributes: AttributeDef[];
    planning: TripPlanning;
    additionalInfo: any;
    driverInfo: string;
    groupingId: any;
    price?: ITripPrice;
    dispatchStrategy?: any;

    route: null | RouteModel;

    @observable hovered: boolean = false;

    constructor(route: null | RouteModel, trip: ITrip) {
        this.route = route;

        this.id = trip.id;
        this.requestedTime = trip.requestedTime;
        this.batchId = trip.batchId;
        this.routeId = trip.routeId;
        this.externalId = trip.externalId;
        this.contract = trip.contract;
        this.anchor = trip.anchor;
        this.sharing = trip.sharing;
        this.distanceLimit = trip.distanceLimit;
        this.timeLimit = trip.timeLimit;
        this.passenger = trip.passenger;
        this.start = trip.start;
        this.end = trip.end;
        this.ride = trip.ride;
        this.capacity = trip.capacity;
        this.attributes = trip.attributes;
        this.planning = trip.planning;
        this.additionalInfo = trip.additionalInfo;
        this.driverInfo = trip.driverInfo;
        this.groupingId = trip.groupingId;
        this.price = trip.price;
        this.dispatchStrategy = trip.dispatchStrategy;
    }

    @computed.struct get stops(): StopModel[] {
        // We need to return references to the StopModels in route.stops, so changes made
        // (e.g. setting hover state) to returned stops will be present in route.stops.
        let pick = this.route?.stops.find((s) => s.id === this.ride.pick.id)!;
        let drop = this.route?.stops.find((s) => s.id === this.ride.drop.id)!;
        if (!pick && this.ride?.pick) {
            pick = new StopModel({ ...this.ride.pick, ...{ location: this.start } }, null, this);
        }
        if (!drop && this.ride?.drop) {
            drop = new StopModel({ ...this.ride.drop, ...{ location: this.end } }, null, this);
        }
        const stops = pick && drop ? [pick, drop] : [];
        return stops;
    }

    @computed get pick(): StopModel {
        return this.stops[0];
    }

    @computed get drop(): StopModel {
        return this.stops[1];
    }

    @computed.struct get pathPoints(): ICoords[] {
        return this.route
            ? this.route.stops
                  .filter(
                      (i) =>
                          i.ordinal >
                              (this.ride.pick.ordinal > 1
                                  ? this.ride.pick.ordinal
                                  : this.ride.pick.ordinal - 1) &&
                          i.ordinal <= this.ride.drop.ordinal
                  )
                  .reduce((a, i) => [...a, ...polyUtil.decode(i.gpath)], [] as ICoords[])
            : [];
    }

    /**
     * Prepare a structure needed to populate price info table
     */
    @computed.struct get priceSectionData(): { [key: string]: string } | undefined {
        const price = this.price;

        if (!price) {
            return undefined;
        }

        const toMoney = (value: number) => `${value.toFixed(2)} ${price.currency}`;

        const prices: { [key: string]: string } = {};

        if (price.total) {
            prices.price = toMoney(price.total);
        }

        if (price.excess) {
            prices.excessPrice = toMoney(price.excess);
        }

        if (price.extraFees && price.extraFees.length > 0) {
            // add a fake fee that will be used as a sub-title
            [{ name: 'extraFees' }, ...price.extraFees].forEach(
                // some extra fees might be without values, but should be shown anyway. Use a space (' ') for them,
                // otherwise the fee will be ignored
                (i) => (prices[i.name] = i.fee ? toMoney(i.fee) : ' ')
            );
        }

        return prices;
    }

    @computed get driverInfos(): DriverInfo {
        const driverInfo = this.driverInfo;

        try {
            const parsed = JSON.parse(driverInfo);

            return {
                driverInfo: parsed.kyyti,
                phoneNumber: parsed.phoneNumber,
                serviceLevel: parsed.serviceLevel,
            };
        } catch (e) {
            return { driverInfo: driverInfo };
        }
    }

    @action
    setStyle(hovered: boolean) {
        this.hovered = hovered;
    }
}
