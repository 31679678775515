import { action, computed, observable } from 'mobx';
import TripModel from './TripModel';
import moment from 'moment';
import RouteModel from './RouteModel';

export default class StopModel implements IStop {
    static get STATE_PERFORMED() {
        return 'performed';
    }
    //static get STATE_PLANNED() { return 'planned' }
    //static get STATE_ARRIVED() { return 'arrived' }
    static get STATE_NO_SHOW() {
        return 'noShow';
    }

    completion: ICompletion;
    distance: number;
    driveTime: number;
    eta: Date;
    gpath: string;
    id: number;
    ordinal: number;
    plannedTime: Date;
    type: StopType;
    location: Location;

    trip?: TripModel;
    route: null | RouteModel;

    @observable hovered: boolean = false;
    @observable selected: boolean = false;
    @observable expanded: boolean = false;

    constructor(stop: IStop, route: null | RouteModel, trip?: TripModel) {
        this.trip = trip;
        this.route = route;

        this.id = stop.id;
        this.ordinal = stop.ordinal;
        this.type = stop.type;
        this.completion = stop.completion;
        this.distance = stop.distance;
        this.driveTime = stop.driveTime;
        this.eta = stop.eta;
        this.gpath = stop.gpath;
        this.plannedTime = stop.plannedTime;
        this.location = stop.location;
    }

    @computed get capacityLoad(): Capacity[] {
        if (!this.route) {
            return [];
        }
        const tmp = this.route.stops
            .filter((s) => s.ordinal <= this.ordinal)
            .reduce((a, s) => {
                const mult = s.type === 'pick' ? 1 : -1;

                if (s.trip) {
                    s.trip.capacity.forEach(
                        (c) => (a[c.unitId] = (a[c.unitId] || 0) + c.count * mult)
                    );
                }

                return a;
            }, {});

        return Object.entries(tmp).map(([unitId, count]: [string, any]) => ({
            unitId: +unitId,
            count: count,
        }));
    }

    @computed get time(): Date {
        return this.actualTime || this.plannedTime;
    }

    @computed get actualTime(): Date {
        return this.completion?.status === StopModel.STATE_PERFORMED && this.completion.timestamp
            ? this.completion.timestamp
            : this.eta;
    }

    @computed get requestedTime(): Date | null {
        return this.trip?.anchor === this.type ? this.trip.requestedTime : null;
    }

    @computed get promisedTime(): Date | null {
        if (this.trip) {
            if (this.type === 'pick') {
                return this.trip.ride.promisedPickTime;
            } else if (this.type === 'drop') {
                return this.trip.ride.promisedDropTime;
            }
        }

        return null;
    }

    @computed get timeDiff(): number {
        const diff = Math.round(
            moment.duration(moment(this.actualTime).diff(moment(this.plannedTime))).asMinutes()
        );

        // sometimes both `eta` and `completion.timestamp` are missing and the value for the `diff` will be `NaN`. Display 0 in this case.
        if (!Number.isNaN(diff)) {
            return diff;
        } else {
            //console.warn('failed to calculate `timeDiff` for the stop', this);

            return 0;
        }
    }

    @computed get icon(): string {
        if (this.completion.status === StopModel.STATE_PERFORMED) {
            return 'check-circle';
        }
        if (this.completion.status === StopModel.STATE_NO_SHOW) {
            return 'minus-circle';
        }

        if (
            this.type === 'drop' &&
            this.trip?.stops[0]?.completion.status === StopModel.STATE_PERFORMED
        ) {
            return 'taxi';
        }

        return '';
    }

    @action setStyle(selected: boolean, hovered: boolean) {
        this.selected = selected;
        this.hovered = hovered;
    }
}
