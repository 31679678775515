import './Itinerary.css';

import React, { Fragment } from 'react';
import { genFormattedAddress } from '../../utils/utils';
import CapacitiesList from '../CapacitiesList/CapacitiesList';
import AttributesList from '../AttributesList/AttributesList';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import StopModel from '../../models/StopModel';
import TimeView from '../TimeView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import MainStore from '../../store/mainStore';
import { WithTranslation, withTranslation } from 'react-i18next';
import InfoTable from '../InfoTable';
import GridTable from '../GridTable';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';
import config from '../../config';

interface ThisComponentProps extends WithTranslation {
    stores?: MainStore;
    stops: StopModel[];
}

@inject('stores')
@observer
class Itinerary extends React.Component<ThisComponentProps, {}> {
    props: any;

    @observable shownDispatchStrategies = {};

    toggleDispatchStrategy(stop) {
        this.shownDispatchStrategies[stop.id] = !this.shownDispatchStrategies[stop.id];
    }

    highlightStops(stop: StopModel, hovered: boolean) {
        (stop.route?.stops || []).forEach((s) => (s.selected = s.hovered = false));

        if (stop.trip) {
            stop.trip.hovered = hovered;
            stop.trip.stops.forEach((i, index) => {
                i.selected = hovered && i.id === stop.id;
                i.hovered = hovered;
            });
        } else {
            stop.hovered = stop.selected = hovered;
        }

        if (hovered && stop.completion.timestamp) {
            this.props.stores.mapStore.setPerform(stop.completion);
        } else {
            this.props.stores.mapStore.setPerform();
        }
    }

    getStopTypeIcon(type: StopType): IconName {
        switch (type) {
            case 'pick':
                return 'plus';
            case 'drop':
                return 'minus';
            case 'flex':
                return 'table';
            default:
                return 'question';
        }
    }

    render() {
        const { t, stores, stops }: { t: any; stores: any; stops: StopModel[] } = this.props;
        const { rootURL } = config.app;

        let dateLineValue: string = '';
        return (
            <table className="itinerary-table">
                <thead>
                    <tr>
                        <th colSpan={2}>{t('label.event')}</th>
                        <th />
                        <th>{t('label.trip')}</th>
                        <th>{t('label.address')}</th>
                        <th>{t('label.load')}</th>
                    </tr>
                </thead>
                <tbody>
                    {stops &&
                        stops.map((i) => (
                            <Fragment key={i.ordinal}>
                                {dateLineValue !==
                                    moment(i.time, undefined, stores.uiStore.lang).format('L') && (
                                    <tr className="itinerary-date-row">
                                        <td colSpan={6}>
                                            {
                                                (dateLineValue = moment(
                                                    i.time,
                                                    undefined,
                                                    stores.uiStore.lang
                                                ).format('L'))
                                            }
                                        </td>
                                    </tr>
                                )}
                                <tr
                                    className={`${i.hovered ? 'hovered' : ''} ${
                                        i.expanded ? 'expanded' : ''
                                    }`}
                                    onMouseEnter={() => this.highlightStops(i, true)}
                                    onMouseLeave={() => this.highlightStops(i, false)}
                                    onClick={() => (i.expanded = !i.expanded)}
                                >
                                    <td>
                                        <FontAwesomeIcon icon={this.getStopTypeIcon(i.type)} />
                                    </td>
                                    <td className="text-nowrap">
                                        <TimeView date={i.time} /> ({i.timeDiff})
                                    </td>
                                    <td>
                                        {i.icon !== '' && <FontAwesomeIcon icon={i.icon as any} />}
                                    </td>
                                    <td>
                                        <Link
                                            className="inline-link"
                                            to={`${rootURL}trip/${i.trip?.id}`}
                                        >
                                            {i.trip?.id || ''}
                                        </Link>
                                    </td>
                                    <td>{genFormattedAddress(i.location)}</td>
                                    <td>
                                        <CapacitiesList capacities={i.capacityLoad} />
                                    </td>
                                </tr>
                                {i.expanded && i.trip && (
                                    <tr className={'expanded details'}>
                                        <td colSpan={6}>
                                            <br />

                                            <InfoTable
                                                rows={{
                                                    passenger: [
                                                        i.trip.passenger.firstName,
                                                        i.trip.passenger.lastName,
                                                    ]
                                                        .filter((i) => i)
                                                        .join(' '),
                                                    externalId: i.trip.externalId,
                                                    addressInfo: i.location.info && i.location.info,
                                                    additionalInfo: i.trip.additionalInfo,
                                                    driverInfo:
                                                        i.trip.driverInfo &&
                                                        i.trip.driverInfos.driverInfo,
                                                    driverPhoneNumber:
                                                        i.trip.driverInfo &&
                                                        i.trip.driverInfos.phoneNumber,
                                                    driverServiceLevel:
                                                        i.trip.driverInfo &&
                                                        i.trip.driverInfos.serviceLevel,
                                                    load: (
                                                        <CapacitiesList
                                                            capacities={i.trip.capacity}
                                                        />
                                                    ),
                                                    attributes:
                                                        i.trip.attributes.length !== -1 ? (
                                                            <AttributesList
                                                                attributes={i.trip.attributes}
                                                            />
                                                        ) : null,
                                                }}
                                            />
                                            <br />

                                            {i.trip.priceSectionData && (
                                                <Fragment>
                                                    <InfoTable rows={i.trip.priceSectionData} />
                                                    <br />
                                                </Fragment>
                                            )}

                                            <GridTable
                                                rows={[
                                                    [
                                                        <TimeView
                                                            date={i.trip.requestedTime}
                                                            showDate={true}
                                                            showTime={false}
                                                        />,
                                                        t('label.pick'),
                                                        t('label.drop'),
                                                    ],
                                                    [
                                                        t('label.requestedTime'),
                                                        <TimeView
                                                            date={i.trip.pick.requestedTime}
                                                        />,
                                                        <TimeView
                                                            date={i.trip.drop.requestedTime}
                                                        />,
                                                    ],
                                                    [
                                                        t('label.promisedTime'),
                                                        <TimeView
                                                            date={i.trip.pick.promisedTime}
                                                        />,
                                                        <TimeView
                                                            date={i.trip.drop.promisedTime}
                                                        />,
                                                    ],
                                                    [
                                                        t('label.plannedTime'),
                                                        <TimeView date={i.trip.pick.plannedTime} />,
                                                        <TimeView date={i.trip.drop.plannedTime} />,
                                                    ],
                                                    [
                                                        t('label.eta'),
                                                        <TimeView date={i.trip.pick.eta} />,
                                                        <TimeView date={i.trip.drop.eta} />,
                                                    ],
                                                    [
                                                        t('label.actualTime'),
                                                        <TimeView date={i.trip.pick.actualTime} />,
                                                        <TimeView date={i.trip.drop.actualTime} />,
                                                    ],
                                                ]}
                                            />

                                            <br />

                                            <h5 className="mt-3">
                                                <span
                                                    onClick={this.toggleDispatchStrategy.bind(
                                                        this,
                                                        i
                                                    )}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={
                                                            this.shownDispatchStrategies[i.id]
                                                                ? 'minus-square'
                                                                : 'plus-square'
                                                        }
                                                    />{' '}
                                                    {t('label.dispatchStrategy')}
                                                </span>
                                            </h5>

                                            {this.shownDispatchStrategies[i.id] && (
                                                <code>
                                                    {JSON.stringify(
                                                        i.trip.dispatchStrategy,
                                                        null,
                                                        4
                                                    )}
                                                </code>
                                            )}

                                            <br />
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                </tbody>
            </table>
        );
    }
}

export default withTranslation()(Itinerary);
