const defConfig = {
    api: {
        url: 'https://loki-api.demo.vinka.cloud',
    },
    google: {
        country: 'fi',
    },
    app: {
        rootURL: '/',
        pingExpiration: 300, // seconds
    },
    auth0: {
        domain: 'vinka.eu.auth0.com',
        clientId: '3iTP4pksqTn1Tad5O3asZnRUgaWFGXq3',
        audience: 'https://loki-api.vinka.cloud',
    },
    map: {
        apiUrl: 'https://api.tomtom.com',
        tilesUrl: 'https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key={apikey}',
        key: '3k2b2u6RMBf4NgQiUOGUlcPtA9Jgzxcp', // TODO: took it from the `commander`
        countrySet: 'FI', // the lookup results will be restricted by the provided country
    },
    ui: {
        customerStyle: '',
    },
};

const envConfig = {
    meneva: {
        app: {
            rootURL: '/',
        },
        map: {
            key: '1X6LwGe4zTnOSXWqpIQPcPuU8Pyjg1UB',
        },
    },
    'meneva-um': {
        app: {
            rootURL: '/',
        },
        map: {
            key: '1X6LwGe4zTnOSXWqpIQPcPuU8Pyjg1UB',
        },
    },
    'meneva-ph': {
        app: {
            rootURL: '/',
        },
        map: {
            key: '1X6LwGe4zTnOSXWqpIQPcPuU8Pyjg1UB',
        },
    },
    'meneva-test': {
        app: {
            rootURL: '/',
        },
        map: {
            key: '1X6LwGe4zTnOSXWqpIQPcPuU8Pyjg1UB',
        },
    },
    localhost: {
        app: {
            rootURL: '/',
        },
        api: {
            url: 'http://localhost:9320',
            // url: 'https://demo.vinka.cloud/loki-api',
        },
        // auth0: {
        //     domain: 'vinka-test.eu.auth0.com',
        //     clientId: 'BU2q7A6h1nChqOxvpMiafExnh4F4wTWS',
        // },
        map: {
            key: 'NckITLLF4t5rrbI5URSnC7BnYlbWA0mL',
        },
    },
    // and so on...
};

class AppConfig {
    config: any;

    constructor() {
        // first apply default config (use its copy!)
        this.config = JSON.parse(JSON.stringify(defConfig));

        // now check if there are any overrides available for the environments. If so - apply them
        const [env, rootURL] = AppConfig.env;
        if (env) {
            const url =
                rootURL === '/'
                    ? `https://loki-api.${env}.vinka.cloud`
                    : `https://${env}.vinka.cloud/loki-api/`;
            const overrideDefaultConfig = Object.assign(
                { api: { url }, app: { rootURL } },
                envConfig[env]
            );

            AppConfig.deepMerge(this.config, overrideDefaultConfig);
        }
    }

    // merge recursively
    static deepMerge(target, source) {
        Object.keys(source) // start iterating through the own keys of the object
            .forEach((k) =>
                source[k] instanceof Object
                    ? AppConfig.deepMerge(target[k] || (target[k] = {}), source[k])
                    : (target[k] = source[k])
            );
    }

    static get env(): [string, string] {
        const [app, env]: string[] = window.location.hostname.split('.');

        if (app === 'localhost') {
            // localhost
            return ['localhost', '/'];
        } else if (app === 'loki') {
            // https://loki.demo.vinka.cloud
            return [env, '/'];
        } else {
            // https://demo.vinka.cloud/loki/
            return [app, '/loki/'];
        }
    }
}

const appConfig = new AppConfig();

export default appConfig.config;

export const getEnv = AppConfig.env;
