import axios, { AxiosInstance } from 'axios';
import { getI18n } from 'react-i18next';
import config from '../config';
import { getAuth0Client } from '../react-auth0-spa';

export class VehicleApi {
    private http: AxiosInstance;

    constructor(url: string) {
        this.http = axios.create({
            baseURL: url,
        });

        // intercept all outgoing requests
        this.http.interceptors.request.use(VehicleApi.beforeRequest);

        // intercept all incoming responses
        this.http.interceptors.response.use(
            VehicleApi.onRequestSuccess,
            VehicleApi.onRequestFailure
        );
    }

    private static async beforeRequest(request) {
        const auth0 = await getAuth0Client();
        const token = await auth0.getTokenSilently();

        // add an auth header
        request.headers.Authorization = `Bearer ${token}`;

        return request;
    }

    private static onRequestSuccess(response) {
        // unwrap returned data
        return response.data.hasOwnProperty('data') ? response.data.data : response;
    }

    private static async onRequestFailure(err) {
        // check for the `401` status and force-logout, otherwise proceed with regular workflow
        if (err.response.status === 401) {
            const auth0 = await getAuth0Client();
            await auth0.logout();
        } else {
            console.log('vehicle fetch failed', err);
        }

        throw err;
    }

    async getVehicles(): Promise<IVehicle[]> {
        try {
            return await this.http.get('/vehicle');
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getVehicleHistory(id: number, from: Date, to: Date): Promise<LogItem<IVehicle>[]> {
        try {
            return await this.http.get(`/vehicle/${id}/logs`, {
                params: {
                    startTime: from.toISOString(),
                    endTime: to.toISOString(),
                },
            });
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getContracts(): Promise<(string | null)[]> {
        try {
            return await this.http.get('/contract');
        } catch (e) {
            console.log('contract fetch failed', e);
        }
        return [];
    }

    async getSchedule(id: number, from: Date, to: Date): Promise<Undef<VehicleScheduleData>> {
        try {
            return await this.http.get(
                `/vehicle/${id}/schedule?startTime=${from.toISOString()}&endTime=${to.toISOString()}`
            );
        } catch (e) {
            console.log('fetch failed', e);
        }

        return undefined;
    }

    async getPings(vehicle: number, from: Date, to: Date): Promise<Undef<IPing[]>> {
        try {
            return await this.http.get(
                `/vehicle/${vehicle}/pings?startTime=${from.toISOString()}&endTime=${to.toISOString()}`
            );
        } catch (e) {
            console.log('fetch failed', e);
        }

        return undefined;
    }

    async getClosestPings(vehicles: number[], date: Date): Promise<Undef<IPing[]>> {
        try {
            return await this.http.get(`/ping/closest`, {
                params: { vehicles: vehicles.join(','), date: date.toISOString() },
            });
        } catch (e) {
            console.log('fetch failed', e);
        }

        return undefined;
    }

    async getRoutePerforms(routeId: number): Promise<IPerform[]> {
        try {
            return await this.http.get(`/route/${routeId}/performs`);
        } catch (e) {
            console.log('fetch failed', e);
            return [];
        }
    }

    async getTripLogs(id: number): Promise<Undef<LogItem<ITrip>[]>> {
        try {
            return await this.http.get(`/trip/${id}/logs`);
        } catch (e) {
            console.log('fetch failed', e);
        }

        return undefined;
    }

    async getRouteLogs(id: number): Promise<Undef<LogItem<IRoute>[]>> {
        try {
            return await this.http.get(`/route/${id}/logs`);
        } catch (e) {
            console.log('fetch failed', e);
        }

        return undefined;
    }

    async getRouteOffers(id: number): Promise<IOffer[]> {
        try {
            return await this.http.get(`/route/${id}/offers`);
        } catch (e) {
            console.log('fetch failed', e);
        }

        return [];
    }

    async getReport(
        vehicles: string[],
        from: Date,
        to: Date
    ): Promise<IReportWrapper<IVehicleReport[]>> {
        try {
            return await this.http.get(
                `/report/vehicle?vehicles=${vehicles.join(
                    ','
                )}&startTime=${from.toISOString()}&endTime=${to.toISOString()}`
            );
        } catch (e) {
            console.log('fetch failed', e);
        }

        return { meta: {}, data: [] };
    }

    async getTripDetails(vehicles: string[], from: Date, to: Date): Promise<any> {
        try {
            return await this.http.get('/report/trip', {
                params: {
                    vehicles: vehicles.join(','),
                    startTime: from.toISOString(),
                    endTime: to.toISOString(),
                    lang: getI18n().language,
                },
                responseType: 'blob',
            });
        } catch (e) {
            console.log('fetch failed', e);
        }

        return null;
    }

    async getTripDailyReport(contract: string | null, from: Date, to: Date): Promise<any> {
        try {
            return await this.http.get('/report/trip/daily', {
                params: {
                    contract,
                    startTime: from.toISOString(),
                    endTime: to.toISOString(),
                    lang: getI18n().language,
                },
            });
        } catch (e) {
            console.log('fetch failed', e);
        }

        return null;
    }

    async searchRoutes(q: string): Promise<IRoute[]> {
        try {
            return await this.http.get(`/route/search/${q}`);
        } catch (e) {
            console.log('rout fetch failed', e);
        }

        return [];
    }

    async searchTrips(q: string): Promise<ITrip[]> {
        try {
            return await this.http.get(`/trip/search/${q}`);
        } catch (e) {
            console.log('trip fetch failed', e);
        }

        return [];
    }
}

const vehicleApi = new VehicleApi(config.api.url);

export default vehicleApi;
