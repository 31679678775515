import { action, computed, observable } from 'mobx';
import { promisedComputed } from 'computed-async-mobx';
import moment from 'moment';
import vehicleApi, { VehicleApi } from './vehicleApi';

export enum ReportType {
    VEHICLE_ACTIVITY = 'vehicleActivity',
    TRIP_DETAILS = 'tripDetails',
    TRIP_DAILY_REPORT = 'tripDailyReport',
}

export default class RouteStore {
    private vehicleApi: VehicleApi;

    @observable searchVehicles: Undef<string[]>;
    @observable searchStartTime: Undef<Date>;
    @observable searchEndTime: Undef<Date>;
    @observable reportType: Undef<string>;
    @observable contract: Undef<string | null>;

    constructor() {
        this.vehicleApi = vehicleApi;
    }

    @action setSearchValues(
        searchVehicles: string[],
        searchStartTime: Date,
        searchEndTime: Date,
        reportType: string,
        contract: string | null
    ) {
        this.setSearchVehicles(searchVehicles);
        this.setSearchStartTime(searchStartTime);
        this.setSearchEndTime(searchEndTime);
        this.setReportType(reportType);
        this.setContract(contract);
    }

    @action setSearchVehicles(searchVehicles: string[]) {
        this.searchVehicles = searchVehicles;
    }

    @action setSearchStartTime(searchStartTime: Date) {
        this.searchStartTime = searchStartTime;
    }

    @action setSearchEndTime(searchEndTime: Date) {
        this.searchEndTime = searchEndTime;
    }

    @action setReportType(reportType: string) {
        this.reportType = reportType;
    }

    @action setContract(str: string | null) {
        this.contract = str;
    }

    @computed get report(): Undef<IReportWrapper<IVehicleReport[] | any[]>> {
        const tmp = this.computedReport;

        return tmp.busy ? undefined : tmp.get();
    }

    computedReport = promisedComputed({ meta: {}, data: [] }, async () => {
        if (this.searchStartTime && this.searchEndTime) {
            if (
                this.reportType === ReportType.TRIP_DAILY_REPORT &&
                this.searchStartTime &&
                this.searchEndTime
            ) {
                return await this.fetchTripDailyReport(
                    this.contract!,
                    this.searchStartTime,
                    this.searchEndTime
                );
            } else if (this.searchVehicles && this.searchVehicles.length > 0) {
                if (this.reportType === ReportType.VEHICLE_ACTIVITY) {
                    return await this.fetchVehicleReport(
                        this.searchVehicles,
                        this.searchStartTime,
                        this.searchEndTime
                    );
                } else if (this.reportType === ReportType.TRIP_DETAILS) {
                    await this.fetchTripDetailsReport(
                        this.searchVehicles,
                        this.searchStartTime,
                        this.searchEndTime
                    );
                    return { meta: {}, data: [] };
                }
            }
        }

        return { meta: {}, data: [] };
    });

    async fetchVehicleReport(
        vehicles: string[],
        startTime: Date,
        endTime: Date
    ): Promise<IReportWrapper<IVehicleReport[]>> {
        return await this.vehicleApi.getReport(vehicles, startTime, endTime);
    }

    async fetchTripDetailsReport(vehicles: string[], startTime: Date, endTime: Date) {
        try {
            const response = await this.vehicleApi.getTripDetails(vehicles, startTime, endTime);

            await RouteStore.saveFile(
                response,
                `${moment(startTime).format('L')}-${moment(endTime).format('L')}_${vehicles}`
            );
        } catch (e) {
            console.log(e);
        }
    }

    async fetchTripDailyReport(contract: string | null, startTime: Date, endTime: Date) {
        try {
            return await this.vehicleApi.getTripDailyReport(contract, startTime, endTime);
        } catch (e) {
            console.log(e);
        }
    }

    static async saveFile(response, name: string) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${name}.xlsx`);
        link.click();
        window.URL.revokeObjectURL(url);
    }
}
