import './VehicleInfo.css';

import React from 'react';
import { useTranslation } from 'react-i18next';
import InfoTable from '../InfoTable';
import AttributesList from '../AttributesList';
import CapacitiesList from '../CapacitiesList';

type ThisComponentProps = {
    vehicleLog: LogItem<IVehicle>;
    prevLog?: LogItem<IVehicle>;
};

function getProp(object, keys, defaultVal) {
    keys = Array.isArray(keys) ? keys : keys.split('.');
    object = object[keys[0]];
    if (object && keys.length > 1) {
        return getProp(object, keys.slice(1), defaultVal);
    }
    return object === undefined ? defaultVal : object;
}

function getVehicleValue(object, keys, defaultVal, t) {
    if (typeof keys === 'function') {
        return keys(object, t);
    }
    return getProp(object, keys, defaultVal);
}

function booleanValue(obj, keys, defaultVal, t) {
    const val = getProp(obj, keys, null);
    return val ? t(`label.yes`) : val === null ? defaultVal : t(`label.no`);
}

function getUseShift(obj, t) {
    const val = booleanValue(obj, 'data.useShift', '', t);
    return val;
}

function getCapacities(obj, t) {
    return obj.data.capacityConfigs.map((caps, index) => (
        <CapacitiesList
            key={`cap-config-${index + obj.data.capacityConfigs.length}`}
            capacities={caps}
        />
    ));
}

function getAttributes(obj, t) {
    return <AttributesList attributes={obj.data.attributes} />;
}

function getContracts(obj, t) {
    return (obj.data.contract || []).join(', ');
}

const attributesDiffer = (vehicleLog, prevLog) => {
    const a = vehicleLog.data.attributes.map((attr) => attr.id);
    const b = prevLog.data.attributes.map((attr) => attr.id);
    return a.join(',') !== b.join(',');
};

const capacitiesDiffer = (vehicleLog, prevLog) => {
    const a = vehicleLog.data.capacityConfigs.map((caps) => JSON.stringify(caps));
    const b = prevLog.data.capacityConfigs.map((caps) => JSON.stringify(caps));
    return a.join(',') !== b.join(',');
};

const hasDifferences = (vehicleLog, prevLog, keys, valueComparator, t) => {
    if (typeof valueComparator === 'function') {
        return valueComparator(vehicleLog, prevLog);
    }
    return getVehicleValue(vehicleLog, keys, '', t) !== getVehicleValue(prevLog, keys, '', t);
};

const formVehicleInfoRows = (vehLog, prev, t) => {
    // array of infos: ['labelname'], ['data accessor'/function], [diff enabled/function]
    const infos: any[][] = [
        ['vehicleNumber', 'data.number', '1'],
        ['name', 'data.name', '1'],
        ['externalId', 'data.externalId', '1'],
        ['phone', 'data.phoneNumber', '1'],
        ['licenceNumber', 'data.licenceNumber', '1'],
        ['priceFactor', 'data.priceFactor', '1'],
        ['emissionFactor', 'data.emissionFactor', '1'],
        ['paymentTerminalId', 'data.paymentTerminalId', '1'],
        ['operator', 'data.operator.companyName', '1'],
        ['capacities', getCapacities, capacitiesDiffer],
        ['attributes', getAttributes, attributesDiffer],
        ['useShift', getUseShift, '1'],
        ['dispatchPriority', 'data.dispatchPriority', '1'],
        ['dispatchArea', 'data.dispatchArea', '1'],
        ['contract', getContracts, '1'],
        // garage
        ['actorUser', 'actor.username'],
        ['actorClient', 'actor.client'],
    ];

    const rows = infos.reduce(
        (all: { rows: Record<any, any>; highlight: string[] }, def: any[]) => {
            all.rows[def[0]] = getVehicleValue(vehLog, def[1], '', t);
            // highlight requirement
            if (prev && def[2] && hasDifferences(vehLog, prev, def[1], def[2], t)) {
                all.highlight.push(def[0]);
            }
            return all;
        },
        { rows: {}, highlight: [] }
    );
    return rows;
};

const VehicleInfo = ({ vehicleLog, prevLog }: ThisComponentProps) => {
    const { t } = useTranslation();
    const rows = formVehicleInfoRows(vehicleLog, prevLog, t);

    return <InfoTable rows={rows.rows} highlight={rows.highlight} />;
};

export default VehicleInfo;
