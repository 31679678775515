import './AttributesList.css';

import React from 'react';
import { useTranslation } from 'react-i18next';

type ThisComponentProps = {
    attributes: AttributeDef[];
};

const AttributesList = ({ attributes }: ThisComponentProps) => {
    const { t } = useTranslation();

    return (
        <div className="attributes-list">
            {attributes.map((i) => (
                <span key={`key-attribute-${i.id}`}>{t(`attribute.${i.id}`, i.id + '')}</span>
            ))}
        </div>
    );
};

export default AttributesList;
